import { Token } from '@sushiswap/core-sdk'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { isAddress } from 'app/functions'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'

import { useAaveOracleContract, useSpookyLpPairContract, useTokenContract } from '..'

function usePrice() {
  const useGovTokenPriceDollar = (govEthLpToken: Token): number => {
    const { chainId, account } = useActiveWeb3React()
    const { WRAPPED_NATIVE } = GET_TOKEN(chainId)
    const lpContract = useSpookyLpPairContract(govEthLpToken.address)
    const aaveOracleContract = useAaveOracleContract()
    const token0ContractRaw = useTokenContract('0x0000000000000000000000000000000000000001')
    const token1ContractRaw = useTokenContract('0x0000000000000000000000000000000000000001')

    const [tokenPrice, setTokenPrice] = useState<number>(0)
    const fetchTokenPrice = useCallback(async () => {
      if (chainId) {
        try {
          const [reserve0, reserve1] = await lpContract?.getReserves()
          const token0Address = await lpContract?.token0()
          const token1Address = await lpContract?.token1()
          const token0Contract = token0ContractRaw?.attach(token0Address)
          const token1Contract = token1ContractRaw?.attach(token1Address)
          const token0Decimal = await token0Contract?.decimals()
          const token1Decimal = await token1Contract?.decimals()

          let quoteTokenAddress
          let quoteTokenDecimals
          let tokenDecimals
          let quoteTokenReserve
          let tokenReserve
          if (token0Address.toLowerCase() === WRAPPED_NATIVE.address.toLowerCase()) {
            quoteTokenAddress = token0Address
            quoteTokenDecimals = token0Decimal
            tokenDecimals = token1Decimal
            quoteTokenReserve = reserve0
            tokenReserve = reserve1
          } else if (token1Address.toLowerCase() === WRAPPED_NATIVE.address.toLowerCase()) {
            quoteTokenAddress = token1Address
            quoteTokenDecimals = token1Decimal
            tokenDecimals = token0Decimal
            quoteTokenReserve = reserve1
            tokenReserve = reserve0
          } else {
            throw Error('LP pair does not consists of WETH')
          }

          const quoteTokenPriceInWei = await aaveOracleContract?.getAssetPrice(quoteTokenAddress)
          const quoteTokenPrice = new BigNumberJS(quoteTokenPriceInWei.toString())
            .div('1e' + quoteTokenDecimals)
            .toNumber()

          const ratio = new BigNumberJS(quoteTokenReserve.toString())
            .div(new BigNumberJS(tokenReserve.toString()))
            .times(new BigNumberJS(10).pow(tokenDecimals - quoteTokenDecimals))

          const tokenPrice = ratio.times(quoteTokenPrice)

          setTokenPrice(tokenPrice.isFinite() ? tokenPrice.toNumber() : 0)
        } catch (error) {
          // setTokenPrice(0)
          // throw error
        }
      }
    }, [chainId, lpContract, aaveOracleContract, token0ContractRaw, token1ContractRaw, WRAPPED_NATIVE.address])

    useEffect(() => {
      if (chainId) {
        fetchTokenPrice()
      }
      const refreshInterval = setInterval(fetchTokenPrice, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchTokenPrice])

    return tokenPrice
  }

  const useAVarTokenPriceDollar = (tokenAddress: string): number => {
    const { chainId, account } = useActiveWeb3React()
    const aaveOracleContract = useAaveOracleContract()

    const [tokenPrice, setTokenPrice] = useState<number>(0)
    const fetchAVarTokenPrice = useCallback(async () => {
      try {
        if (chainId) {
          if (!isAddress(tokenAddress)) {
            return 0
          }
          const priceInWei = await aaveOracleContract?.getAssetPrice(tokenAddress)
          const price = new BigNumberJS(priceInWei.toString()).div(1e18)
          setTokenPrice(price.toNumber())
        }
      } catch (error) {
        // setTokenPrice(0)
        // throw error
      }
    }, [aaveOracleContract, chainId, tokenAddress])

    useEffect(() => {
      if (chainId) {
        fetchAVarTokenPrice()
      }
      const refreshInterval = setInterval(fetchAVarTokenPrice, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchAVarTokenPrice])

    return tokenPrice
  }

  const useAVarTokensPriceDollar = (tokenAddresses: string[]): number[] => {
    const { chainId, account } = useActiveWeb3React()
    const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
    const aaveOracleContract = useAaveOracleContract()

    const [tokenPrices, setTokenPrices] = useState<number[]>([])
    const fetchATokenPrice = useCallback(async () => {
      try {
        if (chainId) {
          const oraclePromises: Promise<string>[] = []
          for (let i = 0; i < tokenAddresses.length; i++) {
            oraclePromises.push(aaveOracleContract?.getAssetPrice(tokenAddresses[i]))
          }
          const oracles = await Promise.all(oraclePromises)
          const prices: number[] = []
          for (let i = 0; i < tokenAddresses.length; i++) {
            const price = new BigNumberJS(oracles[i].toString()).div(new BigNumberJS(1e18))
            prices.push(price.toNumber())
          }
          setTokenPrices(prices)
        }
      } catch (error) {
        // setTokenPrices([])
        // throw error
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AVAR_TOKENS, aaveOracleContract, chainId, JSON.stringify(tokenAddresses)])

    useEffect(() => {
      if (chainId) {
        fetchATokenPrice()
      }
      const refreshInterval = setInterval(fetchATokenPrice, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchATokenPrice])

    return tokenPrices
  }

  const useLPPriceDollar = (lpToken: Token): number => {
    const { chainId, account } = useActiveWeb3React()
    const { WRAPPED_NATIVE } = GET_TOKEN(chainId)
    const lpContract = useSpookyLpPairContract(lpToken.address)
    const aaveOracleContract = useAaveOracleContract()
    const token0ContractRaw = useTokenContract('0x0000000000000000000000000000000000000001')
    const token1ContractRaw = useTokenContract('0x0000000000000000000000000000000000000001')

    const [lpPrice, setLPPrice] = useState<number>(0)
    const fetchLPPrice = useCallback(async () => {
      try {
        if (chainId) {
          const [reserve0, reserve1] = await lpContract?.getReserves()
          const token0Address = await lpContract?.token0()
          const token1Address = await lpContract?.token1()
          const lpTotalSupply = await lpContract?.totalSupply()
          const token0Contract = token0ContractRaw?.attach(token0Address)
          const token1Contract = token1ContractRaw?.attach(token1Address)
          const token0Decimal = await token0Contract?.decimals()
          const token1Decimal = await token1Contract?.decimals()

          let quoteTokenAddress
          let quoteTokenDecimals
          let tokenDecimals
          let quoteTokenReserve
          let tokenReserve
          if (token0Address.toLowerCase() === WRAPPED_NATIVE.address.toLowerCase()) {
            quoteTokenAddress = token0Address
            quoteTokenDecimals = token0Decimal
            tokenDecimals = token1Decimal
            quoteTokenReserve = reserve0
            tokenReserve = reserve1
          } else if (token1Address.toLowerCase() === WRAPPED_NATIVE.address.toLowerCase()) {
            quoteTokenAddress = token1Address
            quoteTokenDecimals = token1Decimal
            tokenDecimals = token0Decimal
            quoteTokenReserve = reserve1
            tokenReserve = reserve0
          } else {
            throw Error('LP pair does not consists of WETH')
          }

          const quoteTokenPriceInWei = await aaveOracleContract?.getAssetPrice(quoteTokenAddress)
          const quoteTokenPrice = new BigNumberJS(quoteTokenPriceInWei.toString())
            .div('1e' + quoteTokenDecimals)
            .toNumber()

          const ratio = new BigNumberJS(quoteTokenReserve.toString())
            .div(new BigNumberJS(tokenReserve.toString()))
            .times(new BigNumberJS(10).pow(tokenDecimals - quoteTokenDecimals))

          const tokenPrice = ratio.times(quoteTokenPrice)

          const lpPriceRaw = new BigNumberJS(quoteTokenReserve.toString())
            .times(quoteTokenPrice)
            .plus(new BigNumberJS(tokenReserve.toString()).times(tokenPrice))
            .div(new BigNumberJS(lpTotalSupply.toString()))

          setLPPrice(lpPriceRaw.toNumber())
        }
      } catch (error) {
        // setLPPrice(0)
        // throw error
      }
    }, [chainId, lpContract, aaveOracleContract, token0ContractRaw, token1ContractRaw, WRAPPED_NATIVE.address])

    useEffect(() => {
      if (chainId) {
        fetchLPPrice()
      }
      const refreshInterval = setInterval(fetchLPPrice, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchLPPrice])

    return lpPrice
  }

  return { useGovTokenPriceDollar, useAVarTokenPriceDollar, useAVarTokensPriceDollar, useLPPriceDollar }
}

export default usePrice
