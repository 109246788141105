import { ChainId, Token } from '@sushiswap/core-sdk'
import config from 'config'

import * as BSC_TOKENS from './bsc'
import * as FANTOM_TOKENS from './fantom'

export const GET_AVAR_TOKEN = (chainId?: ChainId) => {
  if (!chainId) {
    return TOKENS[config.defaultChainId]
  }
  return TOKENS[chainId] ?? TOKENS[config.defaultChainId]
}

declare type AddressMap = {
  [chainId: number]: { [address: string]: Token }
}

const TOKENS: AddressMap = {
  [ChainId.BSC]: {
    [BSC_TOKENS.SCULPT.address]: BSC_TOKENS.SCULPT,
    [BSC_TOKENS.SCULPTETH.address]: BSC_TOKENS.SCULPTETH,
    // BNB
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c': BSC_TOKENS.WRAPPED_NATIVE,
    '0x0b0D58E3b57B93a19e48235F5cBF267949Ffe1Dc': new Token(
      ChainId.BSC,
      '0x0b0D58E3b57B93a19e48235F5cBF267949Ffe1Dc', //a
      18,
      'sBNB', //a
      'Sculptor BNB' //a
    ),
    '0x832A3C7dd416fAE2fcAf3aCC46b89030cd2cA11F': new Token(
      ChainId.BSC,
      '0x832A3C7dd416fAE2fcAf3aCC46b89030cd2cA11F', //a
      18,
      'vBNB', //a
      'Sculpt Variable Debt BNB Token' //a
    ),
    // CAKE
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82': new Token(
      ChainId.BSC,
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      18,
      'CAKE',
      'PancakeSwap Token'
    ),
    '0xe8b2b5c794C88e3D45395375f61B45F82B3E25da': new Token(
      ChainId.BSC,
      '0xe8b2b5c794C88e3D45395375f61B45F82B3E25da', //a
      18,
      'sCAKE', //a
      'Sculptor CAKE' //a
    ),
    '0xE26634a59C7C7aAaC3c3D5F75D5E27A82E23a29D': new Token(
      ChainId.BSC,
      '0xE26634a59C7C7aAaC3c3D5F75D5E27A82E23a29D', //a
      18,
      'vCAKE', //a
      'Sculpt Variable Debt CAKE Token' //a
    ),
    // BUSD
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': new Token(
      ChainId.BSC,
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      18,
      'BUSD',
      'Binance-Peg BUSD Token'
    ),
    '0xe12173D162Efb1c861D5884bBDEB036B81b52e6C': new Token(
      ChainId.BSC,
      '0xe12173D162Efb1c861D5884bBDEB036B81b52e6C', //a
      18,
      'sBUSD', //a
      'Sculptor BUSD' //a
    ),
    '0x69d6898FeB05A140beF4b74E39eB2f29eBbca907': new Token(
      ChainId.BSC,
      '0x69d6898FeB05A140beF4b74E39eB2f29eBbca907', //a
      18,
      'vBUSD', //a
      'Sculpt Variable Debt BUSD Token' //a
    ),
    // DAI
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3': new Token(
      ChainId.BSC,
      '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      18,
      'DAI',
      'Binance-Peg Dai Token'
    ),
    '0x54B601a0E3E0f8a3603Fa7A123CDA7ee74B42bD6': new Token(
      ChainId.BSC,
      '0x54B601a0E3E0f8a3603Fa7A123CDA7ee74B42bD6', //a
      18,
      'sDAI', //a
      'Sculptor DAI' //a
    ),
    '0x4bf9341eb66795b4D0bAC55D03cb91F3227AAd53': new Token(
      ChainId.BSC,
      '0x4bf9341eb66795b4D0bAC55D03cb91F3227AAd53', //a
      18,
      'vDAI', //a
      'Sculpt Variable Debt DAI Token' //a
    ),
    // USDT
    '0x55d398326f99059fF775485246999027B3197955': new Token(
      ChainId.BSC,
      '0x55d398326f99059fF775485246999027B3197955',
      18,
      'USDT',
      'Binance-Peg BSC-USD'
    ),
    '0x0368b1dae3Ad17524467f8aC8AABf84c3a5Bdb90': new Token(
      ChainId.BSC,
      '0x0368b1dae3Ad17524467f8aC8AABf84c3a5Bdb90', //a
      18,
      'sUSDT', //a
      'Sculptor USDT' //a
    ),
    '0x50AE3b70E52C26847bFe432384B61887b28e8bAF': new Token(
      ChainId.BSC,
      '0x50AE3b70E52C26847bFe432384B61887b28e8bAF', //a
      18,
      'vUSDT', //a
      'Sculpt Variable Debt USDT Token' //a
    ),
    // USDC
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': new Token(
      ChainId.BSC,
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      18,
      'USDC',
      'Binance-Peg USD Coin'
    ),
    '0x5E56d6c6038b2d799f3c50bc8DefC98E7E2CAA55': new Token(
      ChainId.BSC,
      '0x5E56d6c6038b2d799f3c50bc8DefC98E7E2CAA55', //a
      18,
      'sUSDC', //a
      'Sculptor USDC' //a
    ),
    '0x040EFf162B321CC88df830d3C6Ae82f808b5941A': new Token(
      ChainId.BSC,
      '0x040EFf162B321CC88df830d3C6Ae82f808b5941A', //a
      18,
      'vUSDC', //a
      'Sculpt Variable Debt USDC Token' //a
    ),
    // ETH
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8': new Token(
      ChainId.BSC,
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      18,
      'ETH',
      'Binance-Peg Ethereum Token'
    ),
    '0xc3e5A3Bed48A1B3A6f40Fada6fF43682130d96D1': new Token(
      ChainId.BSC,
      '0xc3e5A3Bed48A1B3A6f40Fada6fF43682130d96D1', //a
      18,
      'sETH', //a
      'Sculptor ETH' //a
    ),
    '0x8F158b99574e8B93258412CAc093717eDeD918bB': new Token(
      ChainId.BSC,
      '0x8F158b99574e8B93258412CAc093717eDeD918bB', //a
      18,
      'vETH', //a
      'Sculpt Variable Debt ETH Token' //a
    ),
    // BTCB
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c': new Token(
      ChainId.BSC,
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      18,
      'BTCB',
      'Binance-Peg BTCB Token'
    ),
    '0x267Fce73c968F540035419227d0742B19CC1b8cA': new Token(
      ChainId.BSC,
      '0x267Fce73c968F540035419227d0742B19CC1b8cA', //a
      18,
      'sBTCB', //a
      'Sculptor BTCB' //a
    ),
    '0x0B7A0257e2dE09b855B536D07C983E610612c42e': new Token(
      ChainId.BSC,
      '0x0B7A0257e2dE09b855B536D07C983E610612c42e', //a
      18,
      'vBTCB', //a
      'Sculpt Variable Debt BTCB Token' //a
    ),
  },
  [ChainId.FANTOM]: {
    [FANTOM_TOKENS.SCULPT.address]: FANTOM_TOKENS.SCULPT,
    [FANTOM_TOKENS.SCULPTETH.address]: FANTOM_TOKENS.SCULPTETH,
    '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E': new Token(
      ChainId.FANTOM,
      '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
      18,
      'DAI',
      'Dai Stablecoin'
    ),
    '0xFe0341D274608CFE099eEC03DFebBE88EDD27e4e': new Token(
      ChainId.FANTOM,
      '0xFe0341D274608CFE099eEC03DFebBE88EDD27e4e',
      18,
      'sDAI',
      'Sculptor DAI'
    ),
    '0x724E2db7C2e41707EdD5A7f4bCBB4f4d8Aa104Eb': new Token(
      ChainId.FANTOM,
      '0x724E2db7C2e41707EdD5A7f4bCBB4f4d8Aa104Eb',
      18,
      'vDAI',
      'Sculpt Variable Debt Token DAI'
    ),

    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83': FANTOM_TOKENS.WRAPPED_NATIVE,
    '0x9B107d0FD81304bA33F78e393b96d3A2D4538188': new Token(
      ChainId.FANTOM,
      '0x9B107d0FD81304bA33F78e393b96d3A2D4538188',
      18,
      'sFTM',
      'Sculptor FTM'
    ),
    '0xa945B9E04FDDE5Bc89F134afF384E00Fb5F1c754': new Token(
      ChainId.FANTOM,
      '0xa945B9E04FDDE5Bc89F134afF384E00Fb5F1c754',
      18,
      'vFTM',
      'Sculpt Variable Debt Token FTM'
    ),

    '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75': new Token(
      ChainId.FANTOM,
      '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      6,
      'USDC',
      'USD Coin'
    ),
    '0x111828513302a59f8Bbde3fA251dA934EB9Af699': new Token(
      ChainId.FANTOM,
      '0x111828513302a59f8Bbde3fA251dA934EB9Af699',
      6,
      'sUSDC',
      'Sculptor USDC'
    ),
    '0x15AA6899ea37De6CE9510b5741b2DF5e22A22c86': new Token(
      ChainId.FANTOM,
      '0x15AA6899ea37De6CE9510b5741b2DF5e22A22c86',
      6,
      'vUSDC',
      'Sculpt Variable Debt Token USDC'
    ),

    '0x049d68029688eAbF473097a2fC38ef61633A3C7A': new Token(
      ChainId.FANTOM,
      '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
      6,
      'USDT',
      'Frapped USDT'
    ),
    '0x202096ced5dB6c58a3Fe0e02111Da126f8F8eba2': new Token(
      ChainId.FANTOM,
      '0x202096ced5dB6c58a3Fe0e02111Da126f8F8eba2',
      6,
      'sUSDT',
      'Sculptor USDT'
    ),
    '0xA0d41DF7FF3ED54d0C05038eb5ADEa907E1cAbA5': new Token(
      ChainId.FANTOM,
      '0xA0d41DF7FF3ED54d0C05038eb5ADEa907E1cAbA5',
      6,
      'vUSDT',
      'Sculpt Variable Debt Token USDT '
    ),
  },
}
